import { useState, useRef, useEffect } from "react";
import ClaimingBenefits from "../Modal/ClaimingBenefits";
import { UUID } from "../../../../Utility/UUID";
import { SpanishRingbaInfo } from "../../../../Utility/SpanishRingbaInfo";
import UnqualifiedRedirectionLinkSpanish from "../../../../Utility/UnqualifiedRedirectionLinkSpanish";
import { useRedirectAndJourneyStatusHandlers } from "../../../../Hooks/useRedirectAndJourneyStatusHandlers";
import SubHeader from "../Header/SubHeader";
import { GoogleGenerativeAI } from "@google/generative-ai";


import {
  questinnare20,
  questinnare21,
  questinnare22,
  questinnare30,
  CALL_CLICK,
} from "../../../../Constants/Questions";
import ChatBotQuestionDisplay from "./ChatBotQuestionDisplay";
import ChatBotAnswerDisplay from "./ChatBotAnswerDisplay";



const apiKey = "AIzaSyCbqXCiZtv1Mn1ZS60rb7sxbm8ficyEAC4";
const genAI = new GoogleGenerativeAI(apiKey);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
  systemInstruction: `If a user purchased a vehicle on finance between 2007 and 2021, it is possible that the car finance was mis-sold due to the car dealership failing to disclose the commission they received from the lender for setting up the finance.
Some lenders allowed dealerships to increase the interest rate paid by consumers, thereby increasing the commission earned by the dealership. This practice led to consumers being overcharged by an estimated £300 million a year.
Interaction Flow(Proceed to ask the following questions in the specified order, prefixing each question with its ID):
{{id[1]}} Start by asking for the user's first name.
{{id[2]}} After receiving the first name, ask for the user's date of birth (DOB).
{{id[3]}} Are you married? (Options: Yes, No)
If the user answers "Yes" ask the below question:
{{id[4]}} Are you a taxpayer? (Options: Yes, No)
If the user married question answers "No" then as this:
{{id[5]}} Are you a private employer? (Options: Yes, No)
Important Notes:
->Ensure the questions are asked in the specified order regardless of their IDs.\n
->Questions must be asked one at a time.\n
->user may also ask any question related to the info provided. Do not append the question to this response, just answer it and then at end ask shall we continue\n
->No mater how many times you ask the same question, you must include the question ID if its a question defined in the flow\n
->Each question must begin with its ID in the format {{id[<number>]}} (even if you append anything else with the question it still must contain the ID)`,

});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

const ChatBotWidget = (props) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [shownQuestions, setShownQuestions] = useState([0]);
  const [showClaimingBenefits, setShowClaimingBenefits] = useState("hidden");
  const [messages, setMessages] = useState([
    // {
    //   sender: "AI",
    //   text: "Hi there! 👋  To help me understand your situation better, could you please tell me your first name?",
    // },
  ]);
  const { fetchUuid } = UUID();
  const { handleOfflineRedirect, handleJourneyStatus, handleUnqualifiedRedirect, handleSplitFormSubmit } = useRedirectAndJourneyStatusHandlers();
  const chatScrollFocus = useRef();
  const splitName = props.splitName;
  const urlParams = new URLSearchParams(window.location.search);
  const utmCampaign = urlParams.get('utm_campaign');
  const {
    phoneNumber,
    formattedPhoneNumber,
    onlineOfflineApiResponse,
    buttonRedirect,
  } = SpanishRingbaInfo("spanish");
  const uuid = fetchUuid();
  const questions = [
    questinnare20,
    questinnare21,
    questinnare22,
    questinnare30,
    CALL_CLICK,
  ];

  const handleOptionClick = (
    selectedOption,
    selectedValue,
    questionIndex,
    click_status,
    click_value
  ) => {
  };
  // const updateJourneyStatus = (slide_type, slide_question, slide_value, type) => {
  //     handleJourneyStatus(uuid, slide_type, slide_question, slide_value, type, splitName);
  // };
  // const getUnqualifiedRedirect = async (click_status) => {
  //     const UnqualifiedRedirectLink = UnqualifiedRedirectionLinkSpanish(selectedOption).redirectionLink;
  //     handleUnqualifiedRedirect(uuid, click_status, splitName, UnqualifiedRedirectLink)
  // };
  // useEffect(() => {
  //     if (selectedOption == 51 || selectedOption == 52) {
  //         formSubmit(selectedAnswers);
  //         if (selectedOption == 52) {
  //             window.location.href = `${window.location.origin}/greencard-thankyou?uuid=${uuid}&split_name=${splitName}&utm_campaign=${utmCampaign}`;
  //         }
  //     }
  // }, [selectedOption, selectedAnswers]);
  const formSubmit = (updatedAnswers) => {
    let formData = {
      lstSalutation: '',
      txtFName: '',
      txtLName: '',
      txtEmail: '',
      txtPhone: '',
      page_name: splitName
    };
    updatedAnswers.map((item) => {
      formData[`question_${item.questionId}`] = item.questionOptionValue;
    });
    handleSplitFormSubmit(formData, splitName)
  }
  const scrollToFocus = () => {
    if (chatScrollFocus.current) {
      const refElement = chatScrollFocus.current;
      const offset = -500;
      const scrollPosition = refElement.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
    else {
      window.scroll(0, 0);
    }
  }

  const [input, setInput] = useState("");
  const [userAnswer, setUserAnswer] = useState({
    questionId: "",
    answer: "",
  });

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (userAnswer.questionId !== "") {
      // setUserAnswer((prevState) => ({
      //   ...prevState,
      //   answer: input,
      // }));
      console.log({
        questionId: userAnswer.questionId,
        answer: input,
      });
      setUserAnswer({
        questionId: "",
        answer: "",
      });
    }
    setInput(""); // Clear the input field

    const userMessage = { sender: "You", text: input };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const conversationHistory = messages.map((msg) => ({
        role: msg.sender === "AI" ? "model" : "user",
        parts: [{ text: msg.text }],
      }));

      conversationHistory.push({ role: "user", parts: [{ text: input }] });

      const chatSession = model.startChat({
        generationConfig,
        history: conversationHistory,
      });

      const result = await chatSession.sendMessage(input);
      const responseText = result.response.text();

      // Handle placeholder extraction
      const idPattern = /\{{id\[(\d+)\]\}}/g;
      let match;
      while ((match = idPattern.exec(responseText)) !== null) {
        setUserAnswer({
          questionId: match[1],
          answer: "",
        });
      }

      // Remove placeholders from the response text
      // const cleanText = responseText.replace(idPattern, "");
      let cleanText = responseText.replace(idPattern, "");
      cleanText = cleanText.replace("\n", "</br>");

      const aiMessage = {
        sender: "AI",
        text: cleanText,
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.log(error);

      const errorMessage = {
        sender: "AI",
        text: "Sorry, something went wrong. Please try again.",
      };
      setMessages((prev) => [...prev, errorMessage]);
    }
  };
  console.log(messages, 4599);

  return (
    <>
      <section className="chat-section mb-5 mrgn-btm">
        <div className="container lg:max-w-[1140px] mx-auto px-0 md:px-4">
          <div className="mt-0 lg:mb-12 md:mb-12 sm:mb-4">
            <div className="max-w-[500px] mx-auto">
              <SubHeader text="Veronica está disponible" />
              <div className="relative min-h-[700px] p-[15px] overflow-hidden">
                <div className="mb-4">
                  <div className="chater-prof" id="slider1">
                    <div style={{ width: "450px" }}>
                      {messages.map((msg, index) => (

                        <div key={index} >
                          <ChatBotQuestionDisplay
                            //questions={questions[index]}
                            msg={msg}
                            index={index}
                            currentQuestionIndex={currentQuestionIndex}
                            handleOptionClick={handleOptionClick}
                            //    updateJourneyStatus={updateJourneyStatus}
                            uuid={uuid}
                            onlineOfflineApiResponse={onlineOfflineApiResponse}
                            phoneNumber={phoneNumber}
                            formattedPhoneNumber={formattedPhoneNumber}
                            scrollToFocus={scrollToFocus}
                          />
                          <ChatBotAnswerDisplay
                            msg={msg}
                            index={index}
                            currentQuestionIndex={currentQuestionIndex}
                            selectedAnswers={selectedAnswers}
                            scrollToFocus={scrollToFocus}

                          />
                        </div>
                      ))}

                    </div>
                    <div ref={chatScrollFocus}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center ">
              <form
                className="flex items-center justify-center w-full space-x-2"
                onSubmit={sendMessage}
              >
                <input
                  className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                  placeholder="Type your message"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ClaimingBenefits
        showClaimingBenefits={showClaimingBenefits}
      //getUnqualifiedRedirect={getUnqualifiedRedirect}
      />
    </>
  );
};

export default ChatBotWidget;
