import { useState } from "react";
import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useUserClicks = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const [isLoading, setIsLoading] = useState(false);
  const saveUserClicks= async (uuid,click_type,status) => {
    setIsLoading(true);
    const response = await Api.post("v1/save-user-clicks", {
        uuid,
        click_type,
        status
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'SAVE USER CLICKS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    setIsLoading(false);
    return response;
  };
  return { saveUserClicks, isLoading };
};
