const questinnare20   = {
  questionId:20,
  question : ["Hola!","¿Quieres ver si calificas?"],
  options: [
          {
              label: "Si, Vamos!",
              value: "47"
          },
          {
              label: "No",
              value: "48"
          }
  ]
};
const questinnare21   = {
  questionId:21,
  question : ["¿Ya tienes seguro medico, Medicare o Medicaid?"],
  options: [
            {
              label: "No",
              value: "50"
            },
            {
              label: "Sí",
              value: "49"
            },
      
  ]
};
const questinnare22   = {
  questionId:22,
  question : ["¿Tienes Green Card, eres Ciudano, eres Residente, o tienes un Permiso para Trabajar en EE.UU?"],
  options: [
          {
              label: "Si",
              value: "51"
          },
          {
              label: "No",
              value: "52"
          }
  ]
};
const questinnare30   = {
  questionId:30,
  question : ["¿Cuál de los siguientes tienes?"],
  options: [
          {
              label: "Obamacare",
              value: "71"
          },
          {
              label: "Medicare",
              value: "72"
          },
          {
            label: "Medicaid",
            value: "73"
          },
          {
            label: "Seguro Con Mi Trabajo",
            value: "74"
          }
  ]
};
const CALL_CLICK   = {
  questionId:'CALL_CLICK',
  question: [`¡Felicidades! 🤩🤩🥳🥳🥳`,
        `Estás precalificado para recibir hasta $6200 en subsidios fiscales para el seguro médico.`,
        `Puedes utilizar los ahorros para las facturas del hogar, el entretenimiento, los viajes y el ocio!`, `Toca el botón de abajo para llamar ahora y reclamar tus beneficios, solo te llevará de 2 a 3 minutitos.`
      ],
  options: [
          {
              label: "833-506-3463",
              value: "CALL_CLICK"
          }
  ]
};
export { 
  questinnare20,
  questinnare21,
  questinnare22,
  questinnare30,
  CALL_CLICK
};
