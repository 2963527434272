import { useState, useEffect } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { PhoneNumberConstants, MedicarePhoneNumberConstants, GreencardPhoneNumberConstants,DentalPhoneNumberConstants } from "../Constants/PhoneNumberConstants";
import { useRingbaInfo } from "../Hooks/useRingbaInfo";
import OfflineSpanishButtonLinkSpanish from "../Utility/OfflineSpanishButtonLinkSpanish";

export const SpanishRingbaInfo = (campaign_name) =>{

const EnvConstant = EnvConstants.AppEnv;
const { postRingbaInfo } = useRingbaInfo();
const [phoneNumber, setPhoneNumber] = useState("");
const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
const [onlineOfflineApiResponse, setOnlineOfflineApiResponse] = useState("");
const [buttonRedirect, setButtonRedirect] = useState();
const query = new URLSearchParams(window.location.search);
const utmName = query.get("utm_campaign");

useEffect(() => {
  switch(campaign_name){
  case "spanish" :
  if(EnvConstant == "prod"){
    if (
      PhoneNumberConstants[utmName] == undefined ||
      PhoneNumberConstants[utmName] == ""
    ) {
      setPhoneNumber(PhoneNumberConstants["default_SPH"]);
    } else {
      setPhoneNumber(PhoneNumberConstants[utmName]);
    }
  }else{
    setPhoneNumber(PhoneNumberConstants['default_local_NO']);
  }
  break;
  case "medicare":
    const utm_phonenumber = MedicarePhoneNumberConstants[utmName]
    ? MedicarePhoneNumberConstants[utmName]
    : MedicarePhoneNumberConstants["default"];
    setPhoneNumber(utm_phonenumber);
   break; 
  case "greencard":
    const greencard_utm_phonenumber = (GreencardPhoneNumberConstants[utmName]) ? GreencardPhoneNumberConstants[utmName] : GreencardPhoneNumberConstants['default'];
    setPhoneNumber(greencard_utm_phonenumber);
    break;
  case "dental":
    const dental_utm_phonenumber = DentalPhoneNumberConstants[utmName]
    ? DentalPhoneNumberConstants[utmName]
    : DentalPhoneNumberConstants["default"];
    setPhoneNumber(dental_utm_phonenumber);
   break;   
  }

  (async() =>{
    if(campaign_name == "greencard"){
      campaign_name = "spanish";
    }
    const data = await postRingbaInfo(campaign_name);
    setOnlineOfflineApiResponse(data.data);
  })();
}, []);

useEffect(() => {
  if (phoneNumber) {
    const digits = phoneNumber.replace(/\D/g, '').slice(-10);
    if (digits.length === 10) {
      const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      setFormattedPhoneNumber(formattedNumber);
    }
  }
  console.log('utmName :',utmName);
  console.log('phoneNumber :',phoneNumber);
}, [phoneNumber]);
useEffect(() => {
  if (onlineOfflineApiResponse) {
    const buttonofflineLink = OfflineSpanishButtonLinkSpanish(onlineOfflineApiResponse.redirect_path);
    setButtonRedirect(buttonofflineLink.buttonRedirectionLink);
  }
}, [onlineOfflineApiResponse]);
return {phoneNumber, formattedPhoneNumber, onlineOfflineApiResponse, buttonRedirect}
}
