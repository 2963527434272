import * as EnvConstants from "../../../../Constants/EnvConstants";
import { Helmet } from "react-helmet";
const EnvConstant = EnvConstants.AppEnv;

const Header = () => {
  return (
    <>
    {(EnvConstant == "prod") ? <Helmet><script src="//b-js.ringba.com/CA4f31afccd82d41f5a77defe4542dffab" async></script></Helmet> : <Helmet><script src="//b-js.ringba.com/CA132c69ac2eb5450c9fbcddbfe2a49ea4" async></script></Helmet>}
      <section>
        <div className="container xl:max-w-[1320px] lg:max-w-[1140px] mx-auto sm:px-4 text-center">
          <h5 className="lg:mt-6 md:mt-6 sm:mt-2 mt-2 font-serif md:text-lg text-sm font-bold text-gray-600 leading-tight mb-2 pr-2 pl-2">
            <span className="text-red-600">Atención: </span>Los agentes están
              extremadamente ocupados. Por favor, no llames a menos que tengas
              de 3 a 5 minutos para reclamar tus beneficios.
          </h5>
          <div className="flex flex-wrap justify-center mt-3">
            <div className="lg:w-[85%] w-full px-3.5 m:pr-4 m:pl-4 lg:mx-1/6">
              <h1 className="font-bold text-[19px]/[23px] sm:text-[32px]/[38px] text-gray-600 mb-2">Desbloquea hasta $6200 en subsidios fiscales para el seguro médico.
                 Descubra cómo los latinos utilizan este Ahorro para las facturas del hogar,
                 el entretenimiento, los viajes y el ocio!</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Header;