import React, { useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
//import "./App.css";

// const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
const apiKey = "AIzaSyCbqXCiZtv1Mn1ZS60rb7sxbm8ficyEAC4";
const genAI = new GoogleGenerativeAI(apiKey);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
  systemInstruction: `If a user purchased a vehicle on finance between 2007 and 2021, it is possible that the car finance was mis-sold due to the car dealership failing to disclose the commission they received from the lender for setting up the finance.
Some lenders allowed dealerships to increase the interest rate paid by consumers, thereby increasing the commission earned by the dealership. This practice led to consumers being overcharged by an estimated £300 million a year.
Interaction Flow(Proceed to ask the following questions in the specified order, prefixing each question with its ID):
{{id[1]}} Start by asking for the user's first name.
{{id[2]}} After receiving the first name, ask for the user's date of birth (DOB).
{{id[3]}} Are you married? (Options: Yes, No)
If the user answers "Yes" ask the below question:
{{id[4]}} Are you a taxpayer? (Options: Yes, No)
If the user married question answers "No" then as this:
{{id[5]}} Are you a private employer? (Options: Yes, No)
Important Notes:
->Ensure the questions are asked in the specified order regardless of their IDs.\n
->Questions must be asked one at a time.\n
->Make sure the user doesnt distract from the question flow and stay on the topics already defined.\n
->No mater how many times you ask the same question, you must include the question ID if its a question defined in the flow\n
->Each question must begin with its ID in the format {{id[<number>]}} (even if you append anything else with the question it still must contain the ID). and if its not a question, just include 0 as id`,
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

const  AIChat = () => {
  const [messages, setMessages] = useState([
    // {
    //   sender: "AI",
    //   text: "Hi there! 👋  To help me understand your situation better, could you please tell me your first name?",
    // },
  ]);
  const [input, setInput] = useState("");
  const [userAnswer, setUserAnswer] = useState({
    questionId: "",
    answer: "",
  });

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    if (userAnswer.questionId !== "") {
      // setUserAnswer((prevState) => ({
      //   ...prevState,
      //   answer: input,
      // }));
      console.log({
        questionId: userAnswer.questionId,
        answer: input,
      });
      setUserAnswer({
        questionId: "",
        answer: "",
      });
    }
    setInput(""); // Clear the input field

    const userMessage = { sender: "You", text: input };
    setMessages((prev) => [...prev, userMessage]);

    try {
      const conversationHistory = messages.map((msg) => ({
        role: msg.sender === "AI" ? "model" : "user",
        parts: [{ text: msg.text }],
      }));

      conversationHistory.push({ role: "user", parts: [{ text: input }] });

      const chatSession = model.startChat({
        generationConfig,
        history: conversationHistory,
      });

      const result = await chatSession.sendMessage(input);
      const responseText = result.response.text();

      // Handle placeholder extraction
      const idPattern = /\{{id\[(\d+)\]\}}/g;
      let match;
      while ((match = idPattern.exec(responseText)) !== null) {
        setUserAnswer({
          questionId: match[1],
          answer: "",
        });
      }

      // Remove placeholders from the response text
      const cleanText = responseText.replace(idPattern, "");

      const aiMessage = {
        sender: "AI",
        text: cleanText,
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.log(error);

      const errorMessage = {
        sender: "AI",
        text: "Sorry, something went wrong. Please try again.",
      };
      setMessages((prev) => [...prev, errorMessage]);
    }
  };

  return (
    <div className="fixed w-full h-full bg-white p-6 rounded-lg border border-[#e5e7eb]">
      <div className="flex flex-col space-y-1.5 pb-6">
        <h2 className="font-semibold text-lg tracking-tight">Chatbot</h2>
      </div>
      <div
        className="h-[80%] overflow-y-auto"
        // style={{ minWidth: "100%", display: "table" }}
      >
        {messages.map((message, index) => (
          <div key={index} className="flex gap-3 my-4 text-gray-600 text-sm">
            <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
              <div className="rounded-full bg-gray-100 border p-1">
                <svg
                  stroke="none"
                  fill="black"
                  strokeWidth={message.sender === "AI" ? 1.5 : 0}
                  viewBox="0 0 24 24"
                  height={20}
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* SVG path based on sender */}
                  {message.sender === "AI" ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                    ></path>
                  ) : (
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                  )}
                </svg>
              </div>
            </span>
            <p className="leading-relaxed">
              <span className="block font-bold text-gray-700">
                {message.sender}{" "}
              </span>
              {message.text}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="flex items-center pt-0 gap-x-2">
        <button className="p-1 px-2 rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6]">
          ssdfsdfsd
        </button>
      </div> */}
      <div className="flex items-center pt-0 mt-2">
        <form
          className="flex items-center justify-center w-full space-x-2"
          onSubmit={sendMessage}
        >
          <input
            className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
            placeholder="Type your message"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default AIChat;