import React, { lazy } from 'react';


const ACA_SPH_V11A = lazy(() => import('./Components/Pages/Splits/ACA_SPH_V11A'));
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const SGA_LP1 = lazy(() => import('./Components/Pages/Splits/split_SGA_LP1'));
const CPS_V3 = lazy(() => import('./Components/Pages/Splits/CPS_V3'));
const AppSplitList = (props) => {

    const SplitListMap = {
        'ACA_SPH_V11A': ACA_SPH_V11A,
        'SGA_LP1':SGA_LP1,
        'CPS_V3': CPS_V3
    };
    
    let SplitComponent;

    if (!(props.splitName in SplitListMap)) {
        SplitComponent = HomePage;
    } else {
        SplitComponent = SplitListMap[props.splitName];
    }
    
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    );

}

export default AppSplitList;