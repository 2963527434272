import Button from "../../../UI/Button";
const ClaimingBenefits = (props) => {
  return (
    <>
      <div
        className={`relative z-10 modal_second ${props.showClaimingBenefits}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-95 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
                    <h3
                      className="text-sm font-bold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      ¡Buenas noticias! Calificas para algunas ofertas
                      fantásticas.Toca el botón de abajo para desbloquearlas, ¡y
                      asegúrate de revisar cada oferta!🤩🤩🥳🥳🥳
                    </h3>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 border-t border-gray-600 px-4 py-3 w-full text-center">
                <Button 
                  buttonText="Reclamar Beneficios"
                  className="anime-pulse bg-[#00a700] text-white border-none w-[90%] h-[35px] text-lg font-medium rounded-lg transition-colors ease-in-out duration-150 custom-animation hover:bg-[#007200]"
                  onClick={(e) => {props.getUnqualifiedRedirect(1)}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimingBenefits;
