const Footer = () => {
  return (
    <>
      <footer className="py-4 text-center text-[13px]">
        <div className="container mx-auto flex justify-center sm:px-4">
          <div className="lg:mx-1/6 lg:w-4/5  pr-4 pl-4">
            <div className="flex flex-wrap justify-center mb-3">
              <div className=" py-2">
                <p className="mb-4">No estamos afiliados ni respaldados por ninguna entidad gubernamental. CMS no ha revisado ni aprobado esta información. Esta no es una descripción completa de los beneficios, visite <a className="cursor-pointer underline ... text-[#033ab3]" target="_blank" href="https://www.healthcare.gov/">healthcare.gov</a> para obtener una lista completa de planes y beneficios disponibles. Los beneficios pueden estar limitados a planes y regiones específicas. Los beneficios enumerados se ofrecen como parte de algunos Planes de Salud Seguro para Latinos y Planes de Medicare Advantage, la inscripción reemplazará su cobertura de salud actual. No hay obligación de inscribirse. </p>
              </div>
              <div className=" py-2">
                <p className="mb-4">*No todos los planes o beneficios están disponibles en todas las áreas. Este beneficio solo está disponible para solicitantes elegibles en los siguientes estados: AL, AZ, FL, GA, MS, NC, SC, TX, UT, VA. No todos los que llaman de estos estados calificarán para el beneficio indicado.</p>
              </div>
              <div className="text-center">
                <p className="mb-4">© 2013-2022 | <a className="cursor-pointer no-underline text-black" target="_blank" href="/Privacy-policy"> Política de Privacidad </a> | <a target="_blank" href="/Terms-of-use">Términos de Uso</a> | <a className="cursor-pointer no-underline text-black" target="_blank" href="/Cookies-policy"> Política de Cookies</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer;