import { useEffect, useState } from "react";
import avatar2 from "../../../../assets/img/ACA_SPH_V11A/avatar.png";
import Loader from "./Loader";

const ChatBotAnswerDisplay = (props) => {
    const [show, setshow] = useState(false)
    useEffect(() => {
        if (props.msg.sender == "AI" && props.msg.text) {
            setshow(true);
            setTimeout(() => {
                console.log(123 );
                props.scrollToFocus();
                setshow(false);
            }, 3000)
          
        }

    }, [props.msg.sender])
    return (
        <>
            {props.msg.sender == "AI" ? <>
                <div id="slider2" className="client-replay my-3" key={`${props.index}`}>
               {show ?  <Loader /> : <>
               <div className="flex justify-end items-center">
                      
                      <div className="dp-client mr-0 ml-2 order-2">
                          <img width="40" height="40" src={avatar2} alt="User Avatar" />
                      </div>
                      <div className="sel-option">
                          <span  className="flex action-btn rounded p-3 px-5" id={`Answer${props.index}`}>
                          <p dangerouslySetInnerHTML={{ __html: props.msg.text }} />
                          </span>
                      </div>
                  </div>
               </>}
                    
                </div>
            </> : ""}


        </>
    );
};

export default ChatBotAnswerDisplay;
