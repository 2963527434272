import React from "react";
import { Api } from "../api/Api";
import { useState } from "react";
import { useErrorWebhook } from "./useErrorWebhook";

export const useJourneyStats = () =>{
    const { ErrorWebhookTrigger } = useErrorWebhook();
    const [loading,isLoading] = useState(false);
    const saveJournyStats = async(uuid,slide_type,slide_question,slide_value,type,split_name)=>{
      isLoading(true);
      const response =await Api.post("v1/save-journey-status",{
        uuid,
        slide_type,
        slide_question,
        slide_value,
        type,
        split_name
      }).catch(function (error) {
        const erroMessage = {
            serviceName: 'SAVE JOURNEY STATUS',
            errorReason: error.message,
            errorCode: error.code,
            uuid: uuid
        }
        ErrorWebhookTrigger(erroMessage);
    });
      return response;
    };
    return { saveJournyStats, loading };
}
