import React, { useContext, useEffect } from 'react';
import { useAdtopiaLoadApi } from '../Hooks/useAdtopiaLoadApi';
import { AppDomainWithoutProtocol, AppDomain, AppLpDefaultUrl, AppAdtopiaUPID, AppReferrerURL } from "../Constants/EnvConstants";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useDataIngestion } from "../Hooks/useDataIngestion";
import { getQueryString } from './QueryString';
import { v1 } from 'uuid';
import * as EnvConstants from "../Constants/EnvConstants";

const AdtopiaLoadLP = ({ splitName }) => {
  const history = useHistory();
  const referrer = document.referrer;
  const { getUUID, setParams } = UUID();
  const { adtopiaLoadResponse, adtopiaLoadingErr } = useAdtopiaLoadApi();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const urlParams = new URLSearchParams(window.location.search);
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const encryptVal = urlParams.get('value');
  const field = 'uuid';
  var uuid = "";
  var queryString = getQueryString();
  const params = new URLSearchParams(queryString);
  useEffect(() => {
    (async () => {
      let uuidType = 'new';
      if (encryptVal || !params.has('tracker')) {
        uuidType = 'new';
      } else {
        uuidType = 'check';
      }
      if (params.has('tracker')) {
        uuid = urlParams.get('uuid');
      } else {
        const getUUIDParam = getUUID(
          splitName,
          uuidType
        );
        uuid = getUUIDParam;
      }
      if (encryptVal) {
        const getdtopiaApi = await adtopiaLoadResponse(
          AppDomain + '/' + splitName + '?' + queryString,
          AppDomainWithoutProtocol,
          window.navigator.userAgent,
          uuid,
          AppAdtopiaUPID,
          AppReferrerURL
        );
        if (adtopiaLoadingErr) {
          history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
        }
        if (typeof getdtopiaApi !== "undefined") {
          const getdtopiaApiResponse = getdtopiaApi.data;
          if (getdtopiaApiResponse.response === 200) {
            if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param);
              } else {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
              }
            } else {
              window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid;
            }
          } else if (getdtopiaApiResponse.response === 404) {
            if (getdtopiaApiResponse.url_param != "") {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
              } else {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
              }
            } else {
              history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
            }
          }
        } else {
          history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
        }
      } else {
        visitorParameters.data = {
          user_agent : window.navigator.userAgent,
          page_type : "LP",
          domain_name:AppDomain,
          existingdomain:window.location.href,
          referer_site:referrer
        };
        visitorParameters.visitor_parameters = { uuid: uuid };
        (async () => {
          await saveDataIngestion(
            visitorParameters.visitor_parameters,
            {},
            'split_page_load',
            splitName,
            queryString,
            visitorParameters.data
          )
        })();
        if (queryString != null) {
          if (queryString.indexOf('&' + field + '=') != -1 ||
            queryString.indexOf('?' + field + '=') != -1 ||
            queryString.indexOf(field + '=') != -1) {
            queryString = queryString.replace(/(uuid=)[^&]+/, `$1${uuid}`);
            window.history.pushState({}, '', '?' + queryString);
          } else {
            window.history.pushState({}, '', '?' + queryString + '&uuid=' + uuid);
          }
        } else {
          window.history.pushState({}, '', '?uuid=' + uuid);
        }
      }
    })()
  }, [encryptVal])
  return (
    <></>
  )
}

export default AdtopiaLoadLP;