import { useState } from "react";
import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useDataIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const saveDataIngestion = async (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data
  ) => {
    setIsLoading(true);
    const response = await Api.post("v2/data-ingestion-pipeline", {
      visitor_parameters,
      ...(Object.keys(data).length!=0 && {data}),
      message_type,
      page_name,
      query_string,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'Data Ingestion : '+message_type,
        errorReason:error.message,
        errorCode:error.code,
        uuid:visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success"
        }
      }
    });
    setIsLoading(false);
    return response;
  };
  const saveDataIngestionV3 = async (
    visitor_parameters,
    data,
    message_type,
    page_name,
    query_string,
    visitor_data
  ) => {
    setIsLoading(true);
    const response = await Api.post("v3/data-ingestion-pipeline", {
      visitor_parameters,
      ...(Object.keys(data).length!=0 && {data}),
      message_type,
      page_name,
      query_string,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'Data Ingestion : '+message_type,
        errorReason:error.message,
        errorCode:error.code,
        uuid:visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success"
        }
      }
    });
    setIsLoading(false);
    return response;
  };

  const saveDataIngestionQuestionV3 = async (
    visitor_parameters,
    question_data,  
    message_type,
    page_name,
    query_string,
    visitor_data
  ) => {
    console.log(message_type,question_data);
    setIsLoading(true);
    const response = await Api.post("v3/data-ingestion-pipeline", {
      visitor_parameters,
      form_data: {}, 
      ...(Object.keys(question_data).length !== 0 && { question_data }), 
      message_type,
      page_name,
      query_string,
      visitor_data,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      };
      ErrorWebhookTrigger(erroMessage);
      return {
        data: {
          status: "Success"
        }
      };
    });
    setIsLoading(false);
    return response;
  };
  
  
  return { saveDataIngestion,saveDataIngestionV3,saveDataIngestionQuestionV3, isLoading };
};
