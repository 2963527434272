import Footer from "../../Includes/Layouts/Footer/Footer";
import Header from "../../Includes/Layouts/Header/Header";
import Faq from "../../Includes/Layouts/FAQ/Faq";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import ChatBotWidget from "../../Includes/Layouts/AIChatBot/ChatBotWidget";


const AIChatBot = () => {
 
  return (
    <>
    <FbViewPixel/>
      <AdtopiaLoadLP splitName = "ACA_SPH_V11A"/>
      <div className="ACA_SPH_V11">
        <Header />
        <ChatBotWidget splitName = "ACA_SPH_V11A"/>
        <Faq />
        <Footer />
      </div>
    </>
  );
};

export default AIChatBot;
