import React, { useState, useEffect } from "react";
import Anchor from "../../../UI/Anchor";
import Avatar from "../Loader/Avatar";
import avatar2 from "../../../../assets/img/ACA_SPH_V11A/avatar2.png";
import Avatar2 from "./Avatar2";
import Loader from "./Loader";


const ChatBotQuestionDisplay = (props) => {
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);
  let is_online = props.onlineOfflineApiResponse.is_online;
console.log(props.index,456);
//   useEffect(() => {
    
//     if (currentDisplayIndex <= props.index) {
//       const timer = setTimeout(() => {
//         setCurrentDisplayIndex((prevIndex) => prevIndex + 1);
//       }, 3000);
//     //   if (currentDisplayIndex == props.questions.question.length - 1) {
//     //     props.updateJourneyStatus(
//     //       props.index == 4 ? "button" : "question",
//     //       props.questions.questionId,
//     //       "",
//     //       "view"
//     //     );
//     //   }
//       return () => clearTimeout(timer);
//     }
//   }, [currentDisplayIndex, props.index]);
useEffect(()=>{
    if(props.msg.sender == "You"){
        props.scrollToFocus();
    }
},[props.msg.sender])
  return (
    <>
      <div key={props.index} className="question-block">
        <div className="question">
          <div className={`mesg${props.index}`}>
              <div key={props.index} className="flex items-end">
                
               {props.msg.sender == "You"? (
                  <>{  props.msg.sender == "You"?
                    <Avatar2 /> : <div className="supporter-ndp" />
                  }<span className="text-msg max-w-[84%] md:max-w-[87%] slide-top"> <p dangerouslySetInnerHTML={{ __html: props.msg.text }} /></span>
                  </>
                ) : null}
              </div>
          </div>
          {/* {currentDisplayIndex >= props.questions.question.length && (
            <>
              {props.index == 3 ? (
                <>
                  <div>
                    <div
                      className={`mesg12  sel-option flex items-end ${props.index == props.currentQuestionIndex
                        ? "visible"
                        : "hidden"
                        }`}
                    >
                      <Avatar />
                      <div className="text-msg-1 py-2 px-3 btn-spc text-center">
                        {props.questions.options.map((option, optionIndex) => (
                          <div className="mt-2 mb-1" key={optionIndex}>
                            <span
                              className="action-btn medibutton w-full block"
                              onClick={() =>
                                props.handleOptionClick(
                                  option.label,
                                  option.value,
                                  props.index
                                )
                              }
                            >
                              {option.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className={`mesg2 sel-option flex items-end ${props.index == props.currentQuestionIndex
                    ? "visible"
                    : "hidden"
                    }`}
                >
                  <Avatar />
                  <div
                    className="text-msg-1 btn-spc text-center"
                  >
                    {props.questions.options.map((option, optionIndex) => (
                      <React.Fragment key={optionIndex} >
                        <span onClick={props.scrollToFocus()} />
                        {option.value == "CALL_CLICK" ? (
                          <>
                            {is_online == "online" ? (
                              <Anchor
                                key={props.key}
                                onClick={() => props.handleOptionClick(option.label, '', props.index)}
                                href={`tel: ${props.phoneNumber}`}
                                children={<span className='action-btn msg01 me-1'>{props.formattedPhoneNumber}</span>}
                              />
                            ) : (
                              <Anchor
                                key={props.key}
                                onClick={() => props.handleOptionClick(option.label, 'CALL_CLICK', props.index, "1", "call_offline")}
                                children={<span className="action-btn msg01 me-1">
                                  Reclamar Beneficios
                                </span>}
                              />
                            )}
                          </>
                        ) : (
                          <span
                            className="action-btn msg01 me-1"
                            onClick={() =>
                              props.handleOptionClick(
                                option.label,
                                option.value,
                                props.index
                              )
                            }
                          >
                            {option.label}
                          </span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default ChatBotQuestionDisplay;
