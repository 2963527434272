import avatar2 from "../../../../assets/img/ACA_SPH_V11A/avatar2.png";
const Avatar2 = () => {
  return (
    <>
      <div className="supporter-dp">
        <img width="40" height="40" src={avatar2} alt="User Avatar" />
      </div>
    </>
  );
};

export default Avatar2;
