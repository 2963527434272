import { useContext } from "react";
import { useDataIngestion } from "../Hooks/useDataIngestion";
import { useJourneyStats } from "../Hooks/useJourneyStats";
import { useUserClicks } from '../Hooks/useUserClicks';
import VisitorParamsContext from "../Contexts/VisitorParams";
import { getQueryString } from '../Utility/QueryString';

export const useRedirectAndJourneyStatusHandlers = () => {
  const { saveDataIngestion } = useDataIngestion();
  const { saveJournyStats } = useJourneyStats();
  const { saveUserClicks } = useUserClicks();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const handleOfflineRedirect = async (uuid, click_status, click_value, splitName, buttonRedirect) => {
    await saveDataIngestion(
      uuid,
      [click_status, click_value],
      "offline",
      splitName
    );
    window.location.href = buttonRedirect;
  };
  const handleJourneyStatus = async (
    uuid,
    slide_type,
    slide_question,
    slide_value,
    type,
    splitName
  ) => {
    const processedSlideValue = slide_value === "CALL_CLICK" ? "" : slide_value;
    await saveJournyStats(
      uuid,
      slide_type,
      slide_question,
      processedSlideValue,
      type,
      splitName
    );
    if(type == "click"){
      if(slide_question == "CALL_CLICK" || slide_question == "MEDICARE_CLICK" || slide_question == "GREENCARD_CLICK" || slide_question == "DENTAL_CLICK"){
        await saveUserClicks(uuid, slide_question, 1);
      }
    }
  };
  const handleUnqualifiedRedirect = async (uuid, click_status, splitName, redirectionLink) => {
    await saveDataIngestion(
      uuid,
      [click_status],
      "unqualify_modal",
      splitName,
      redirectionLink
    );
    window.location = redirectionLink;
  };
  const handleSplitFormSubmit = async(formData, splitName) =>{
    console.log("split form submit")
    await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      'split_form_submit',
      splitName,
      getQueryString(),
      visitorParameters.data
    );
  }
  return { handleOfflineRedirect, handleJourneyStatus, handleUnqualifiedRedirect, handleSplitFormSubmit }
 
};
