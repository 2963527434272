import React from "react";

const Button = (props) => {
  return (
    <button
      type="button"
      className={props.className}
      name={props.name}
      id={props.id}
      onClick={props.onClick}
      disabled={props.disabled}
      value={props.value}
    >
      {props.buttonText}
    </button>
  );
};
export default Button;

