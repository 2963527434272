export const Split_Faq = [
    {
        heading: "¿Qué es Obamacare?",
        content: "<p> Obamacare, conocido oficialmente como la Ley de Cuidado de Salud a Bajo Precio (ACA por sus siglas en inglés), es una ley integral de reforma del sistema de salud promulgada en marzo de 2010 durante la presidencia de Barack Obama. Los objetivos principales de la ACA son aumentar la calidad y asequibilidad del seguro de salud, reducir la tasa de personas sin seguro mediante la expansión de la cobertura de seguro y disminuir los costos de la atención médica para los individuos y el gobierno.</p>"
    },
    {
        heading: "¿Cuáles son los requisitos de inscripción de Obamacare?",
        content: "<p>Tener un estatus migratorio en los Estados Unidos, o estar en proceso de obtenerlo. <br /> No ser beneficiario de Medicare, Medicaid o CHIP. <br /> Cumplir al menos con el ingreso mínimo establecido. <br /> No estar detenido en prisión. </p>"
    },
    {
        heading: "¿Cuáles son los requisitos de inscripción de Obamacare?",
        content: '<p> Cuando seas elegible para un plan del Mercado de Seguros Médicos, el gobierno ofrece un crédito fiscal o subsidio. Esto ayuda a reducir una parte o la totalidad de la "Prima Mensual" de tu póliza de seguro de salud. El monto del crédito fiscal depende de:</p><ul><li>La composición de tu unidad familiar según tu declaración de impuestos.</li><li>La edad de cada individuo que busca cobertura médica.</li><li>Los ingresos anuales de todos los miembros de la familia.</li><li>Tu código postal.</li></ul>'
    },
    {
        heading: "¿Puede negársele el seguro de salud debido a una condición preexistente?",
        content: '<p>Esto depende del año de inscripción de la póliza de seguro médico. Si la inscripción ocurrió en 2010 o después, por ley, el asegurador está prohibido de negar la cobertura o aumentar las primas debido a condiciones preexistentes. Sin embargo, si la inscripción ocurrió antes de 2010, el individuo puede tener un plan "abuelo". Tales planes podrían suspender la cobertura o aumentar las tarifas debido a condiciones preexistentes.</p>'
    },
];

export const split_SGA_LP1 = [
    {
        heading: "What is a $0 premium policy?",
        content: "<p>To keep costs low, the federal government contracts with private insurance companies and pays them a flat fee. The insurance company then creates agreements with a network of local hospitals or providers, which makesit more affordable for you.</p> <p>Your plan replaces traditional Medicare coverage where Part A is hospital insurance, Part B is medical insurance, and Part D provides prescription drug coverage.</p><p>Depending on the plan you are eligible for, and what is available in your state, your plan may also cover extra services like hearing, vision, dental, and other wellness programs that traditional Medicare does not.</p>"
    },
    {
        heading: "Am I eligible for a $0 premium policy?",
        content: "<p>4 out of 5 applicants are eligible for a $0 premium policy and 80% of customers will pay no more than $10 per month. Eligibility depends on the combination of a number of factors including your age, income, and location.</p><p>But don’t worry - we will determine your eligibility based on the answers provided in your application form. If you aren’t eligible for a $0 premium policy, we will find you the best value option for your needs.</p>"
    },
    {
        heading: "Does it include dental?",
        content: "<p>The majority of policies also include routine and emergency dental care. Where this is not offered as standard, it can usually be purchased as an add-on.</p>"
    },
    {
        heading: "Will I be covered for pre-existing medical conditions?",
        content: "<p>Yes. Pre-existing conditions do not disqualify eligibility.</p>"
    },
    {
        heading: "When will I need to pay for medical care?",
        content: "<p>There will often be some costs to pay when you access medical care. These include deductibles, coinsurance, and/or copays and represent the share you pay out of your own pocket when you receive care.</p><p>These costs will be clearly outlined in your policy documents and your carrier will be happy to clarify any queries.</p>"
    }
];
export const split_SPH_SGA_LP1 = [
    {
        heading: "¿Qué es una póliza con prima de $0?",
        content: "<p>Para mantener bajos los costos, el gobierno federal contrata a compañías de seguros privadas y les paga una tarifa fija. La compañía de seguros luego crea acuerdos con una red de hospitales o proveedores locales, lo que lo hace más asequible para usted. Su plan reemplaza la cobertura tradicional de Medicare, donde la Parte A es seguro hospitalario, la Parte B es seguro médico, y la Parte D proporciona cobertura de medicamentos recetados. Dependiendo del plan para el que sea elegible y de lo que esté disponible en su estado, su plan también puede cubrir servicios adicionales como audición, visión, dental y otros programas de bienestar que Medicare tradicional no cubre.</p>"
    },
    {
        heading: "¿Soy elegible para una póliza con prima de $0?",
        content: "<p>4 de cada 5 solicitantes son elegibles para una póliza con prima de $0 y el 80% de los clientes pagarán no más de $10 por mes. La elegibilidad depende de la combinación de varios factores, incluyendo su edad, ingresos y ubicación. Pero no se preocupe: determinaremos su elegibilidad en función de las respuestas proporcionadas en su formulario de solicitud. Si no es elegible para una póliza con prima de $0, le encontraremos la opción de mejor valor para sus necesidades.</p>"
    },
    {
        heading: "¿Incluye dental?",
        content: "<p>La mayoría de las pólizas también incluyen atención dental de rutina y de emergencia. Cuando esto no se ofrece como estándar, generalmente se puede adquirir como un complemento.</p>"
    },
    {
        heading: "¿Estaré cubierto por condiciones médicas preexistentes?",
        content: "<p>Sí. Las condiciones preexistentes no descalifican la elegibilidad.</p>"
    },
    {
        heading: "¿Cuándo tendré que pagar por la atención médica?",
        content: "<p>A menudo, habrá algunos costos que pagar cuando acceda a la atención médica. Estos incluyen deducibles, coaseguro y/o copagos, y representan la parte que usted paga de su propio bolsillo cuando recibe atención. Estos costos estarán claramente detallados en sus documentos de póliza y su compañía de seguros estará encantada de aclarar cualquier duda.</p>"
    }
];