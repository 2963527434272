const SubHeader = (props) => {
  return (
    <>
      <div className="mb-1 support-online flex justify-center items-center align-middle py-4 text-center">
        <span className="flex h-4 w-4 relative">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span className="absolute inline-flex rounded-full h-4 w-4 bg-green-500"></span>
        </span>
        <span className="ps-2">{props.text}</span>
      </div>
    </>
  );
};
export default SubHeader;
