import loader from "../../../../assets/img/ACA_SPH_V11A/loader.gif";
import avatar from "../../../../assets/img/ACA_SPH_V11A/avatar.png";
const Loader = () => {
  return (
    <>
      <div className="chater-prof" id="slider1">
        <div className="mb-1">
          <div className="supporter-dp mb-1">
            <img width="40" height="40" src={avatar} alt="" />
          </div>
        </div>
        <div>
          <div className="msg-loader loader0">
            <div className="text-msg"> <img width="35" height="15" src={loader} alt="" className="h-[15px]" /></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
