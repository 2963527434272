import { Api } from "../api/Api";
import { useErrorWebhook } from "./useErrorWebhook";

export const useRingbaInfo = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const postRingbaInfo = async (campaign_name) => {
    const response = await Api.post("v1/ringba-online", {
      "campaign_name":campaign_name
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'RINGBA ONLINE',
        errorReason: error.message,
        errorCode: error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const saveRingbaDob =  async(dobData) => {
    const { uuid, DobDay, DobMonth, DobYear } = dobData;
    const response =  await Api.post("v1/ringba-dob-save",{
      uuid,
      DobDay,
      DobMonth,
      DobYear
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'RINGBA DOB SAVE',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
  };

  return { postRingbaInfo, saveRingbaDob };
};
