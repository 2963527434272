import React from "react";

const Anchor = ({
  key,
  children,
  href,
  onClick
}) => {
  return (
    <>
      <a
        key={key}
        href={href}
        onClick={onClick}
      > {children}
      </a>
    </>
  );
};
export default Anchor;
