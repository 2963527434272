import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './assets/css/index.css';
// import {GTMHead} from "./Components/External/GTMHead"


import "./assets/css/split_SGA_LP1/input.css";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <>
  {/* <GTMHead/> */}
  <App />
  </>
);